input.form-control {
    &.error {
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, .4);
        -moz-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0,0.4);
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, .4);
    }
}

.form-control {
    color: #232323;
    border: 1px solid #dddddd;
    font-size: 1rem;
    &:focus {
        background: #ffffff;
        border: 1px solid #ebebeb;
        @include boxShadow(0 0 0 .2rem rgba($main-color, .4));
    }
    @include placeholder(#3e3e3e);

    &.checkbox-courses-parts, &.checkbox-inline-with-label {
        display: inline;
        width: 5%;
        vertical-align: middle;
    }
}
.form-inline .form-control {
    &.form-control-number {
        padding: .5rem 0.5rem;
        width: 4rem;
        height: calc(1.5em + 1rem + 2px);
        line-height: 1.5;
        font-weight: bold;
        text-align: center;
    }
}

.input-group {
    margin-left: 1rem;
    @include display-flex;
    .input-number-decrement,
    .input-number-increment {
        width: 2rem;
        justify-content: center;
        align-items: center;
        border: 1px solid #dddddd;
        font-weight: bold;
        cursor: pointer;
        @include display-flex;
    }
    .input-number {
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }
    }
}

.form-list-number {
    margin: 1rem 0;
    padding: 0 0 0 1rem;
    list-style: decimal;
    font-weight: bold;
    .form-list-item {
        padding-left: 1.5rem;
        @include respond-below(md) {
            padding-left: .5rem;
        }
    }
    label {
        font-weight: normal;
    }
}

.form-register, .form-search {
    margin: 30px 0;
    @include clearfix;
}

.form,
.form-row {
    textarea {
        min-height: 10rem;
    }

    .custom-control {
        .ticket-description {
            font-size: 80%;
            margin-left: 2rem;
            font-weight: 100;
        }
    }

    .custom-control-label {
        &::before,
        &::after {
            top: 0.1rem;
            left: -2rem;
            width: 1.25rem;
            height: 1.25rem;
        }
    }
    .custom-control-label {
        margin-left: 0.5rem;
    }
    .custom-control-lm {
        margin-left: 2rem;
    }
    .custom-control-input {
        &:checked ~ .custom-control-label {
            &::before {
                border-color: $main-color;
                background-color: $main-color;
            }
        }
        &:focus ~ .custom-control-label {
            &::before {
                @include boxShadow(0 0 0 .2rem rgba($main-color, .4));
            }
        }
        &:focus::not(:checked) ~ .custom-control-label {
            &::before {
                border-color: $main-color;
            }
        }
    }
    span.required {
        color: #dd0000;
    }
    span.break {
        margin-top: 0;
        margin-bottom: 1rem;
        display: inline-block;
    }
}

.form-newsletter {
    .form-control {
        height: 40px;
        font-size: 1rem;
    }
    .form-group-submit {
        margin-bottom: 0;
        position: relative;
        width: 100%;
        @include clearfix;
        input, button {
            float: left;
        }
        input {
            padding-right: 80px;
            width: 100%;
        }
        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            &:focus {
                outline: none;
            }
        }
        p.messages {
            margin-top: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .btn-submit {
        height: 40px;
        font: 1rem/100% $font-title-sec;
        background: #FFF;
        color: #232323;
        @include rounded(0);
    }
    &--main {
        .form-control {
            color: #ffffff;
            border-color: #4dbdbf;
            background: #17a9ad;
            @include placeholder(#d1eeef);
            &:focus {
                color: #ffffff;
                background: darken(#17a9ad, 5%);
                @include boxShadow(0 0 0 .2rem rgba(darken(#17a9ad, 5%), .4));
            }
        }
        .btn-submit {
            color: #232323;
            border-color: #ffffff;
            background: #ffffff;
            &:hover, &:focus {
                color: lighten(#232323, 10%);
                background: darken(#ffffff, 10%);
            }
        }
        p.messages {
            &.error {
            }
        }
    }
    &--bottom {
        .form-control {
            color: #ffffff;
            border-color: #848484;
            background: #626262;
            @include placeholder(#cfcfcf);
            &:focus {
                color: #ffffff;
                background: lighten(#47464a, 10%);
                @include boxShadow(0 0 0 .2rem rgba(lighten(#47464a, 10%), .4));
            }
        }
        .btn-submit {
            color: #232323;
            border-color: #ffffff;
            background: #ffffff;
            &:hover, &:focus {
                color: lighten(#232323, 10%);
                background: darken(#ffffff, 10%);
            }
        }
        p.messages {
            &.error {
                color: #d00;;
            }
        }
    }
}

.radio-border {
    margin: 0 0 10px 0;
    padding: 20px 25px 20px 40px;
    border: 1px solid #dddddd;
    label {
        display: block;
    }
    .icon {
        filter: grayscale(1);
    }
    .icon--payu {
        position: absolute;
        top: -2px;
        right: 0;
    }
    .icon--invoice {
        position: absolute;
        top: -10px;
        right: 0;
    }
    &.active {
        font-weight: bold;
        border-color: #479951;
        .icon {
            filter: grayscale(0);
        }
    }
}

.datepicker-dropdown {
    z-index: 200 !important;
}