.header {
    &-title {
        margin: 0;
        padding: 0;
        &--simple {
            margin: 14px 0;
            color: #ffffff;
            font: bold 1.875rem/100% $font-head;
        }
        &--name {
            margin: 50px 0 50px 0;
            color: #000000;
            font: bold 1.4375rem/100% $font-head;
            span {
                display: inline-block;
                position: relative;
                &::after {
                    content: '';
                    width: 40px;
                    height: 5px;
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: -13px;
                    z-index: 100;
                    background: $main-color;
                }
            }
        }
        &--section {
            margin: 5px 0 40px 0;
            color: #000000;
            font: bold 1.4375rem/100% $font-head;
            span {
                display: inline-block;
                position: relative;
                &:after {
                    content: '';
                    width: 40px;
                    height: 5px;
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: -13px;
                    z-index: 100;
                    background: $main-color;
                }
            }
            a {
                color: #000000;
                text-decoration: none;
                &:hover,
                &:focus {
                    color: lighten(#000000, 40%);
                }
            }
        }
        &--content {
            margin: 18px 0 40px 0;
            color: #000000;
            font: bold 1.875rem/100% $font-head;
            span {
                display: inline-block;
                position: relative;
                &:after {
                    content: '';
                    width: 40px;
                    height: 5px;
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: -13px;
                    z-index: 100;
                    background: $main-color;
                }
            }
        }
        &--promotional {
            margin: 20px 0 50px 0;
            color: #ffffff;
            font: normal 2.5rem/100% $font-title-extra;
            span {
                display: inline-block;
                position: relative;
                &::after {
                    content: '';
                    width: 50px;
                    height: 10px;
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: -25px;
                    z-index: 100;
                    background: $main-color;
                }
            }
            a {
                color: #ffffff;
                text-decoration: none;
                &:hover,
                &:focus {
                    color: darken(#ffffff, 10%);
                }
            }
        }
        &--white {
            color: #ffffff;
        }
    }
}

.label {
    &-itembox {
        background: #fedb65;
        color: #000000;
    }
}

.contentbox {
    &--details {
        .contentbox-header {
            min-height: 226px;
            @include respond-below(lg) {
                min-height: auto;
            }
        }
        .contentbox-main-hld {
            border: 1px solid #dddddd;
            border-top: none;
        }
        &.open-trainings {
            p.city {
                margin-right: 40px;
            }
        }
        &.publications {
            .contentbox-image-hld {
                img {
                    object-fit: contain;
                }
            }
        }
    }
    &--register {
        .contentbox-main-hld {
            border: 1px solid #dddddd;
        }
        &.open-trainings {
            p.city {
                margin-right: 40px;
            }
        }
    }
    &--contact {
        .contentbox-main-hld {
            margin-bottom: 60px;
            border: 1px solid #dddddd;
        }
    }
    &--thank {
        .contentbox-main-hld {
            padding-bottom: 40px;
            margin-bottom: 60px;
            border: 1px solid #dddddd;
        }
    }
    &-header {
        padding-top: 20px;
        padding-bottom: 20px;
        @include respond-below(md) {
            margin: 0 auto;
            max-width: 540px;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
        .label {
            padding: 5px 10px;
            font-size: 0.75rem;
            text-align: right;
        }
        &-hld {
            background: #292c37;
            .container {
                position: relative;
                @include respond-below(md) {
                    max-width: 100%;
                }
            }
        }
        .event-details {
            @include display-flex;
            flex-wrap: wrap;
            p {
                margin-bottom: 0;
                color: #ffffff;
                align-items: center;
                justify-content: center;
                @include display-flex;
                &.city {
                    .icon {
                        min-width: 43px;
                    }

                    i,
                    span {
                    }
                }
                &.location {
                    .icon {
                        min-width: 26px;
                    }
                    i,
                    span {
                    }
                }
            }
            .icon {
                opacity: 0.7;
            }
            &--form {
                .icon--calendar {
                    @include inline-svg-icon('icon-calendar', #b0b0b0, center, 43px 41px);
                }
                .icon--location {
                    @include inline-svg-icon('icon-location', #b0b0b0, center, 26px 26px);
                }
                p {
                    color: #000000;
                    &.location {
                        @include respond-below(md) {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }
    &-title {
        &-hld {
            margin-bottom: 36px;
            position: relative;
            &::after {
                content: '';
                width: 50px;
                height: 10px;
                display: block;
                position: absolute;
                left: 0;
                bottom: -20px;
                z-index: 100;
                background: $main-color;
            }
            .title {
                margin: 0 0 10px 0;
                padding: 0;
                color: #ffffff;
                font: bold 2.5rem/110% $font-title;
                a {
                    color: #ffffff;
                    text-decoration: none;
                    &:hover,
                    &:focus {
                        color: darken(#ffffff, 10%);
                    }
                }
                &--form {
                    color: #000000;
                }
                @include respond-below(md) {
                    font-size: 1.875rem;
                }
                @include respond-below(lg) {
                    font-size: 1.875rem;
                }
            }
            .subtitle {
                margin: 15px 0;
                padding: 0;
                color: #bfc0c3;
                font: italic 1.375rem/110% $font-title-sec;
                &--form {
                    color: #4c4c4c;
                }
                @include respond-below(md) {
                    font-size: 1rem;
                }
                @include respond-below(lg) {
                    font-size: 1rem;
                }
            }
        }
    }
    &-main {
        &-hld {
            padding-left: 30px;
            padding-right: 30px;
            margin-bottom: 40px;
            background: #ffffff;
        }
        ol {
            margin: 20px 0;
            padding: 0;
            li {
                margin: 5px 0 5px 30px;
                padding: 0 0 0 5px;
            }
        }
        ul {
            margin: 20px 0;
            padding: 0;
            list-style: none;
            li {
                margin: 5px 0;
                padding: 0 0 0 24px;
                position: relative;
                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: 3px;
                    top: 7px;
                    border-radius: 50%;
                    background: $main-color;
                }
                li {
                    &::before {
                        width: 8px;
                        height: 8px;
                        background: transparent;
                        box-shadow: 0 0 0 2px $main-color;
                    }
                    li {
                        &::before {
                            width: 10px;
                            height: 10px;
                            background: $main-color;
                            border-radius: unset;
                            box-shadow: unset;
                        }
                    }
                }
            }
            &.list--file {
                margin: 25px 0 25px 0;
            }
        }
        .col-left {
        }
        .col-right {
            p {
                margin: 0;
            }
            .author-name {
                margin: 1rem 0 0.5rem 0;
                font-weight: bold;
                &:first-child {
                    margin-top: 1rem;
                }
            }
        }
    }
    &-image-hld {
        padding: 0;
        position: absolute;
        top: -1px;
        bottom: -1px;
        right: 0;
        z-index: 120;
        background: #fff;
        clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%);
        -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%);
        @include display-flex;
        @include respond-below(md) {
            height: 300px;
            position: static;
            top: 0;
            right: 0;
            clip-path: none;
            -webkit-clip-path: none;
        }
        .label {
            padding: 5px 10px;
            font-size: 0.75rem;
            position: absolute;
            top: 20px;
            left: 0;
            z-index: 100;
            text-align: left;
        }
        img {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            @include transition(all 0.2s);
        }
    }
    &-action {
        &--top,
        &--bottom {
            .contentbox-action-hld {
                align-items: center;
                @include display-flex;
                @include justify-content(space-between);
                .btn-hld,
                .social-hld {
                    text-align: right;
                    @include display-flex;
                    .btn-primary--fax {
                        margin-right: 5px;
                    }
                }
                .price-hld {
                    @include display-flex;
                    @include flex-direction(column);
                }
                .social-hld {
                    align-items: center;
                }
                @include respond-below(lg) {
                    flex-wrap: wrap;
                    flex-direction: column;
                    height: 100px;
                    .btn-hld,
                    .social-hld {
                        flex: 0 0 50%;
                        justify-content: flex-end;
                        width: calc(100% - 50%);
                    }
                    .price-hld {
                        width: 50%;
                        flex: 0 0 100%;
                        align-items: center;
                    }
                    .social-hld {
                        align-items: flex-end;
                    }
                }
                @include respond-below(lg) {
                    height: auto;
                    .btn-hld,
                    .social-hld {
                        width: 100%;
                        justify-content: center;
                    }
                    .price-hld {
                        margin-bottom: 1rem;
                        width: 100%;
                        justify-content: center;
                    }
                    .social-hld {
                        margin-top: 1rem;
                    }
                }
            }
            .list--social {
                margin: 0;
                .list-item--envelope {
                    margin-left: 20px;
                }
            }
        }
        &.closed-trainings {
            @include respond-between(md, lg) {
                .contentbox-action-hld {
                    flex-direction: row;
                    .btn-hld {
                        justify-content: flex-start;
                    }
                }
            }
        }
        &--top {
            margin: 20px 0;
        }
        &--bottom {
            margin: 30px 0;
        }
    }
}

.page-content {
    margin: 20px 0;
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.contact {
    &-details {
        .header {
            margin: 30px 0;
        }
        .row {
            @include respond-below(lg) {
                @include flex-direction(column-reverse);
            }
        }
        p {
            margin-bottom: 1.5rem;
        }
        .image {
            img {
                margin-bottom: 1rem;
            }
        }
    }
    &-image {
        &-hld {
            overflow: hidden;
            clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
        }
        @include respond-below(md) {
            display: none;
        }
    }
    &-form {
        .header {
            margin: 30px 0;
        }
    }
}

.areasactivity {
    padding: 35px 0 65px 0;
    background: #ffffff;
    .row {
        margin-bottom: 80px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .data {
        .header {
            margin: 30px 0;
        }
    }
    .image {
        align-items: center;
        @include display-flex;
        a {
            img {
                position: relative;
                z-index: 1001;
                @include transition(all 0.2s);
            }
            &:hover,
            &:focus {
                img {
                    @include scale3d(1.1, 1.1, 1);
                }
            }
        }
    }
    .is-odd {
        .image {
            text-align: right;
            @include respond-above(md) {
                padding-left: 30px;
            }
            @include respond-above(lg) {
                padding-left: 60px;
            }
        }
        @include respond-below(md) {
            @include flex-direction(column-reverse);
        }
    }
    .is-even {
        .image {
            text-align: left;
            text-align: right;
            @include respond-above(md) {
                padding-right: 30px;
            }
            @include respond-above(lg) {
                padding-right: 60px;
            }
        }
        @include respond-below(md) {
            @include flex-direction(column-reverse);
        }
        @include flex-direction(row-reverse);
    }
}

.list {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #000000;
    &--icon {
        .list-item {
            padding: 8px 0;
            list-style: none;
        }
    }
    &--social {
        display: inline-block;
        vertical-align: middle;
        line-height: 100%;
        .list-item {
            margin: 0;
            padding: 0;
            width: 32px;
            height: 32px;
            background: #d7d7d7;
            display: inline-block;
            @include rounded(32px);
            @include transition(all 0.2s);
            &::before {
                display: none;
            }
            &:hover,
            &:focus {
                background: darken(#d7d7d7, 10%);
            }
            .icon {
                width: 32px;
                height: 32px;
                display: block;
                &--twitter {
                    background-size: 18px 14px;
                }
                &--facebook {
                    background-size: 10px 18px;
                }
                &--linkedin {
                    background-size: 16px 16px;
                }
                &--envelope {
                    background-size: 17px 15px;
                }
                &--print {
                    background-size: 18px 18px;
                }
            }
        }
    }
    &--file {
        margin: 25px 0 25px 5px;
        .list-item {
            margin: 10px 0 10px 5px;
            padding: 0 0 0 28px;
            list-style: none;
            &::before {
                background: none;
                border-radius: unset;
                content: '';
                width: 18px;
                height: 23px;
                display: inline-block;
                @include inline-svg-icon('icon-pdf', #ffffff, center, 18px 23px);
                position: absolute;
                top: 3px;
                left: 0;
            }
            a {
                color: #000000;
                text-decoration: none;
                vertical-align: middle;
                &:hover,
                &:focus {
                    color: lighten(#000000, 70%);
                    text-decoration: none;
                }
            }
        }
    }
    &--partners {
        .list-item {
            margin: 10px;
            padding: 0;
            list-style: none;
            display: inline-block;
            &::before {
                display: none;
            }
            a {
                color: #000;
                font: normal 24px/100% $font-title;
                text-decoration: none;
                &:hover,
                &:focus {
                    color: lighten(#000000, 40%);
                    img {
                        opacity: 0.4;
                    }
                }
            }
        }
    }
    &--definitions {
        dt {
            padding: 10px 10px 5px 10px;
            color: #000;
            font: normal 20px/100% $font-title;
            a {
                color: #000;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: lighten(#000000, 40%);

                    img {
                        opacity: 0.4;
                    }
                }
            }
            &.empty {
                margin-bottom: 10px;
                padding-bottom: 15px;
                border-bottom: 1px solid #ddd;
            }
        }
        dd {
            padding: 10px 12px;
            border-bottom: 1px solid #ddd;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    &--white {
        .list-item {
            color: #ffffff;
            display: flex;
            align-items: left;
            .icon--location {
                margin-left: 5px;
            }
            a {
                color: #ffffff;
                text-decoration: none;
                &:hover,
                &:focus {
                    color: lighten(#000000, 70%);
                    text-decoration: none;
                }
            }
        }
    }
}

.list-2-col {
    margin: 0;
    padding: 0;
    list-style: none;
    &-inner {
        width: 50%;
        float: left;
    }
}

.price {
    margin: 0.5rem 0;
    vertical-align: middle;
    display: inline-block;
    font: bold 1.25rem/100% $font-head;
    &-value {
        font: bold 2.5rem/100% $font-head;
        color: $main-color;
        white-space: pre;
    }
    &-currency {
        font: bold 1.25rem/100% $font-head;
        color: $main-color;
    }
    &-tax {
        font: bold 1.25rem/100% $font-head;
    }
    &--action {
        margin-bottom: 20px;
        font-size: 1.5rem;
    }
    &--workshop {
        margin-top: 20px;
        .price-value {
            font-size: 2rem;
        }
        .price-currency,
        .price-tax {
            font-size: 1rem;
        }
    }

    &--more {
        margin: 0 0 0.7143rem 0;
        font-size: 1rem;
        .price-value {
            font-size: 1.25rem;
        }
        .price-currency,
        .price-tax {
            font-size: 1rem;
        }
    }
}
