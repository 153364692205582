@mixin clearfix() {
    &::before, &::after {
        content: "";
        display: table;
    }
    &::after {
        clear: both;
    }
    & {
        zoom: 1;
    }
}

@mixin fix-wrap() {
    &::before, &::after {
        content: normal !important;
    }
}

@mixin animation($args...) {
    animation: $args;
    -webkit-animation: $args;
}

@mixin rounded($par: 0px) {
    -webkit-border-radius: $par;
    -moz-border-radius: $par;
    border-radius: $par;
}


@mixin boxShadow($par: 0px) {
    box-shadow: $par;
    -moz-box-shadow: $par;
    -webkit-box-shadow: $par;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin rotate($par) {
    transform: rotate($par);
    -webkit-transform: rotate($par); 
    -moz-transform: rotate($par);
    -o-transform: rotate($par);
}

@mixin skewX($par) {
    transform: skewX($par);
    -webkit-transform: skewX($par);
    -ms-transform: skewX($par);
    -o-transform: skewX($par);
    -moz-transform: skewX($par);
}

@mixin scale($par) {
    transform: scale($par);
    -webkit-transform: scale($par); 
    -moz-transform: scale($par);
    -o-transform: scale($par);
}

@mixin scale3d($args...) {
    transform: scale3d($args);
    -webkit-transform: scale3d($args); 
    -moz-transform: scale3d($args);
    -o-transform: scale3d($args);
}

@mixin placeholder($color) {
    &::placeholder { color: $color; }
    &::-webkit-input-placeholder { color: $color; }
    &::-moz-placeholder, &:-moz-placeholder { color: $color; opacity: 1; }
    &:-ms-input-placeholder { color: $color; }
}

@mixin gradient($from, $to) {
    background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
    background: -moz-linear-gradient(top, $from, $to);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}

@mixin fog($from, $to) {
    background: -moz-linear-gradient(left, $from 0%, $to 100%);
    background: -webkit-linear-gradient(left, $from 0%, $to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=1);
}

@mixin grayscale($par: 1) {
    -webkit-filter: grayscale($par);
    filter: grayscale($par);
}

@mixin display-flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex-direction($par) {
    -webkit-flex-direction: ($par);
    -moz-flex-direction: ($par);
    -ms-flex-direction: ($par);
    -o-flex-direction: ($par);
    flex-direction: ($par);
}

@mixin flex-wrap($par) {
    -webkit-flex-wrap: ($par);
    -moz-flex-wrap: ($par);
    -ms-flex-wrap: ($par);
    -o-flex-wrap: ($par);
    flex-wrap: ($par);
}
@mixin align-items($par) {
    -webkit-box-align: $par;
    -webkit-align-items: $par;
    -ms-flex-align: $par;
    align-items: $par;
}

@mixin justify-content($par) {
    -webkit-justify-content: ($par);
    -ms-flex-pack: ($par);
    justify-content: ($par);
}

@mixin webkit-padding($top, $right, $bottom, $left) {
    -webkit-padding-before: ($top);
    -webkit-padding-end: ($right);
    -webkit-padding-after: ($bottom);
    -webkit-padding-start: ($left);
}

@mixin respond-above($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
        $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
        @media (min-width: $breakpoint-value) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-below($breakpoint) {
    @if map-has-key($grid-breakpoints, $breakpoint) {
        $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-between($lower, $upper) {

    @if map-has-key($grid-breakpoints, $lower) and map-has-key($grid-breakpoints, $upper) {

        $lower-breakpoint: map-get($grid-breakpoints, $lower);
        $upper-breakpoint: map-get($grid-breakpoints, $upper);

        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }

    } @else {

        @if (map-has-key($grid-breakpoints, $lower) == false) {
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        @if (map-has-key($grid-breakpoints, $upper) == false) {
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}

@mixin button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:focus,
    &.focus {
        color: $color;
        background-color: $background;
        border-color: $border;
    }
    &:hover {
        color: $color;
        background-color: $background;
        border-color: $border;
    }
    &:active,
    &.active {
        color: $color;
        background-color: $background;
        border-color: $border;

        &:hover,
        &:focus,
        &.focus {
            color: $color;
            background-color: $background;
            border-color: $border;
        }
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }
}