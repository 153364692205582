$body-bg: #e5e5e5;

$font-std: Verdana, Arial, sans-serif;
$font-head: 'OpenSans', Arial, sans-serif;
$font-title: 'OpenSansCondensed', Arial, sans-serif;
$font-title-sec: 'OpenSansSemiBold', Arial, sans-serif;
$font-title-extra: 'OpenSansExtraBold', Arial, sans-serif;

$main-color: #059b99;

$content-font-size: 14px;

$content-color-text: #000000;
$content-color-link: #057a79;
$content-color-link-hover: darken(#057a79, 10%);

$highlighted-bg: #d9edf7;