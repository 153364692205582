.popup-modal {
    top: 50% !important;
    left: 50% !important;
    transform: translate3d(-50%, -50%, 0);
    padding: 0 !important;
}

.popup-backdrop {
    opacity: 0.95 !important;
}

.popup-container {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    margin: 80px auto 0;
    background: white;
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1);
    text-align: center;

    .popup-body {
        box-sizing: border-box;
        margin-bottom: 25px;
        font: bold 1.2rem/100% $font-title-sec;
        text-transform: uppercase;
    }

    .popup-btn {
        margin: 15px;
        padding: 15px 25px;
        font-size: 20px;
        color: #fff;
        box-sizing: border-box;
        border: none;
        cursor: pointer;
        display: inline-block;
        @include transition(all .2s);

        &.popup-agree {
            background-color: #2ecc71;
            box-shadow: 0px 5px 0px 0px #15B358;
            text-shadow: 1px 2px 0px #15b358;
            &:hover {
                background-color: #48E68B;
            }
        }

        &.popup-cancel {
            background-color: #e74c3c;
            box-shadow: 0px 5px 0px 0px #CE3323;
            text-shadow: 1px 2px 0px #CE3323;
            &:hover {
                background-color: #FF6656;
            }
        }
    }
}
