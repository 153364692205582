@media print,
       (min-resolution: 1.25dppx),
       (min-resolution: 120dpi) {
    /* Style adjustments for high resolution devices */
}

@media print {
    .container {
        width: auto;
        height: auto;
        float: left;
    }

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
        float: left;
    }

    .col-sm-12 { width: 100% }
    .col-sm-11 { width: 91.66667% }
    .col-sm-10 { width: 83.33333% }
    .col-sm-9 { width: 75% }
    .col-sm-8 { width: 66.66667% }
    .col-sm-7 { width: 58.33333% }
    .col-sm-6 { width: 50% }
    .col-sm-5 { width: 41.66667% }
    .col-sm-4 { width: 33.33333% }
    .col-sm-3 { width: 25% }
    .col-sm-2 { width: 16.66667% }
    .col-sm-1 { width: 8.33333% }

    .col-sm-pull-12 { right: 100% }
    .col-sm-pull-11 { right: 91.66667% }
    .col-sm-pull-10 { right: 83.33333% }
    .col-sm-pull-9 { right: 75% }
    .col-sm-pull-8 { right: 66.66667% }
    .col-sm-pull-7 { right: 58.33333% }
    .col-sm-pull-6 { right: 50% }
    .col-sm-pull-5 { right: 41.66667% }
    .col-sm-pull-4 { right: 33.33333% }
    .col-sm-pull-3 { right: 25% }
    .col-sm-pull-2 { right: 16.66667% }
    .col-sm-pull-1 { right: 8.33333% }
    .col-sm-pull-0 { right: auto }

    .col-sm-push-12 { left: 100% }
    .col-sm-push-11 { left: 91.66667% }
    .col-sm-push-10 { left: 83.33333% }
    .col-sm-push-9 { left: 75% }
    .col-sm-push-8 { left: 66.66667% }
    .col-sm-push-7 { left: 58.33333% }
    .col-sm-push-6 { left: 50% }
    .col-sm-push-5 { left: 41.66667% }
    .col-sm-push-4 { left: 33.33333% }
    .col-sm-push-3 { left: 25% }
    .col-sm-push-2 { left: 16.66667% }
    .col-sm-push-1 { left: 8.33333% }
    .col-sm-push-0 { left: auto }

    .col-sm-offset-12 { margin-left: 100% }
    .col-sm-offset-11 { margin-left: 91.66667% }
    .col-sm-offset-10 { margin-left: 83.33333% }
    .col-sm-offset-9 { margin-left: 75% }
    .col-sm-offset-8 { margin-left: 66.66667% }
    .col-sm-offset-7 { margin-left: 58.33333% }
    .col-sm-offset-6 { margin-left: 50% }
    .col-sm-offset-5 { margin-left: 41.66667% }
    .col-sm-offset-4 { margin-left: 33.33333% }
    .col-sm-offset-3 { margin-left: 25% }
    .col-sm-offset-2 { margin-left: 16.66667% }
    .col-sm-offset-1 { margin-left: 8.33333% }
    .col-sm-offset-0 { margin-left: 0 }

    .visible-xs {
    display: none !important;
    }
    .hidden-xs {
    display: block !important;
    }
    table.hidden-xs {
    display: table;
    }
    tr.hidden-xs {
    display: table-row !important;
    }
    th.hidden-xs,
    td.hidden-xs {
    display: table-cell !important;
    }
    .hidden-xs.hidden-print {
    display: none !important;
    }
    .hidden-sm {
    display: none !important;
    }
    .visible-sm {
    display: block !important;
    }
    table.visible-sm {
    display: table;
    }
    tr.visible-sm {
    display: table-row !important;
    }
    th.visible-sm,
    td.visible-sm {
    display: table-cell !important;
    }
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    html, body {
        border: 1px solid white;
        height: 99%;
        page-break-after: avoid;
        page-break-before: avoid;
    }

    // *,
    // *:before,
    // *:after {
    //     border: 1px solid #000;
    // }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
        display: none;
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    /*
     * Bootstrap components
     */

    .navbar {
        display: none;
    }

    .table {
        td,
        th {
        background-color: #fff !important;
        }
    }

    .btn,
    .dropup > .btn {
        > .caret {
            border-top-color: #000 !important;
        }
    }

    .label {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table-bordered {
        th,
        td {
            border: 1px solid #ddd !important;
        }
    }

    .sf-toolbar {
        display: none !important;
    }

    .headersite,
    .footersite {
        display: none !important;
    }

    .insetbox,
    .theytrusted,
    .promotional {
        display: none !important;
    }

    .fb-box-hld,
    .in-box-hld,
    .alert-cookie {
        display: none !important;
    }

    .contentbox--details .contentbox-main-hld {
        border: none;
    }

    .contentbox-image-hld .label {
        background: #fff !important;
    }
}