.navbar {
    margin: 0;
    padding: 0;
    float: right;
    &-inner {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    &-item {
        margin: 0;
        padding: 0;
        display: inline-block;
        font: normal 1.25rem/100% $font-title-sec;
        a {
            padding: 30px 20px 38px 20px;
            display: block;
            color: #000000;
            text-decoration: none;
            &:hover,
            &:focus {
                color: lighten(#000000, 70%);
                text-decoration: none;
            }
        }
        &--orange {
            a {
                color: #f14902;
                &:hover,
                &:focus {
                    color: lighten(#f14902, 30%);
                }
            }
        }
        @include respond-below(xl) {
            font-size: 1rem;
            a {
                padding: 30px 15px;
            }
        }
    }
    &--mobile {
        .navbar-item {
            display: block;
            a {
                padding: 20px 20px;
            }
        }
    }
}

.nav-help {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #ffffff;
    font-size: 1rem;
    &-item {
        margin: 0;
        padding: 10px 20px;
        display: inline-block;
        a {
            color: #ffffff;
            text-decoration: none;
            &:hover,
            &:focus {
                color: lighten(#000000, 70%);
                text-decoration: none;
            }
        }
        .js-search-show {
            background: transparent;
        }
    }
    @include respond-below(xl) {
        font-size: 0.75rem;
    }
}

.nav-bull {
    margin: 0;
    padding: 0;
    list-style: none;
    &-item {
        margin: 4px 0;
        a {
            color: #ffffff;
            text-decoration: none;
            &:hover,
            &:focus {
                color: lighten(#000000, 70%);
                text-decoration: none;
            }
        }
        &::before {
            content: '';
            margin-right: 10px;
            width: 5px;
            height: 9px;
            display: inline-block;
            @include inline-svg-icon('icon-arrow-right', #059b99, center, 5px 9px);
        }
    }
    @include respond-below(xl) {
        font-size: 0.75rem;
    }
}

.drawer-nav {
    width: 80%;
    .logo {
        margin: 20px 0;
        text-align: center;
        img {
            width: auto;
            height: 40px;
        }
    }
    hr {
        width: 90%;
        border-top: 1px dashed #dddddd;
    }
    .list--social {
        margin: 10px 0;
        display: block;
        text-align: center;
        .list-item {
            margin: 0 5px;
        }
    }
    .drawer-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

.drawer--left .drawer-nav {
    left: -80%;
}
