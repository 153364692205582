body {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 360px;
    height: 100%;
    font: normal $content-font-size $font-std;
    line-height: 180%;
    color: $content-color-text;
    background: $body-bg;
    a {
        color: $content-color-link;
        text-decoration: underline;
        @include transition(all 0.2s);
        &:hover,
        &:focus {
            color: $content-color-link-hover;
            text-decoration: none;
            outline: none;
        }
        img {
            @include transition(all 0.2s);
        }
    }
    @include respond-below(lg) {
        padding-top: 62px;
        font-size: 13px;
    }
}

i {
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}

figure {
    margin: 0;
}

.pull {
    &-right {
        float: right;
    }
    &-left {
        float: left;
    }
}

.center {
    text-align: center;
}

.alert {
    flex: auto;
    p:last-child {
        margin-bottom: 0 !important;
    }
}

.grecaptcha-badge {
    display: none;
}

.highlight {
    background: #ffff5f;
}

.hidden {
    display: none;
}

.site-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    background-color: #0f0f0fc4;
}

button {
    &:focus {
        outline: none;
    }
}

.btn {
    border: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    outline: none;
    text-align: center;
    @include transition(all 0.2s);
    &-border {
        margin: 0;
        padding: 15px 25px;
        font: normal 1rem/100% $font-title-sec;
        border: 1px solid #dddddd;
        background: #ffffff;
        color: #292c37;
        &:hover,
        &:focus {
            color: lighten(#292c37, 10%);
            background: darken(#ffffff, 10%);
            border-color: lighten(#292c37, 50%);
        }
    }
    &-primary {
        margin: 0;
        padding: 15px 25px;
        font: normal 1rem/100% $font-title-sec;
        border: 1px solid #b11623;
        background: #b11623;
        color: #ffffff;
        &:hover,
        &:focus {
            color: darken(#ffffff, 10%);
            background: darken(#b11623, 10%);
        }
        &--white {
            border: 1px solid #ffffff;
            background: #ffffff;
            color: #292c37;
            &:hover,
            &:focus {
                color: lighten(#292c37, 15%);
                background: darken(#ffffff, 15%);
                border-color: #292c37;
            }
        }
    }
    &-mobile {
        background: transparent;
        .icon {
            margin-right: 0;
            &--menu {
                width: 32px;
                height: 32px;
                @include inline-svg-icon('icon-menu', #292c37, center, 32px 32px);
            }
            &--search {
                width: 32px;
                height: 32px;
                @include inline-svg-icon('icon-search', #292c37, center, 28px 28px);
            }
        }

        @include respond-above(lg) {
            display: none;
        }
        &:hover,
        &:focus {
            outline: none;
        }
    }
    &-sm {
        padding: 5px 15px;
        font: normal 0.8125rem/100% $font-title-sec;
    }
}

.icon {
    margin-right: 10px;
    &--arrow-up {
        width: 12px;
        height: 7px;
        @include inline-svg-icon('icon-arrow-up', #292c37, center, 11px 7px);
    }
    &--arrow-right {
        width: 7px;
        height: 12px;
        @include inline-svg-icon('icon-arrow-right', #292c37, center, 7px 11px);
    }
    &--arrow-down {
        width: 12px;
        height: 7px;
        @include inline-svg-icon('icon-arrow-down', #292c37, center, 11px 7px);
    }
    &--arrow-left {
        width: 7px;
        height: 12px;
        @include inline-svg-icon('icon-arrow-left', #292c37, center, 7px 11px);
    }
    &--calendar {
        width: 43px;
        height: 41px;
        @include inline-svg-icon('icon-calendar', #ffffff, center, 43px 41px);
    }
    &--cart {
        width: 25px;
        height: 24px;
        @include inline-svg-icon('icon-cart', #ffffff, center, 25px 24px);
    }
    &--email {
        margin-bottom: 2px;
        width: 30px;
        height: 20px;
        @include inline-svg-icon('icon-email', #ffffff, center, 30px 20px);
    }
    &--help {
        width: 26px;
        height: 26px;
        @include inline-svg-icon('icon-help', #ffffff, center, 26px 26px);
    }
    &--location {
        width: 26px;
        height: 26px;
        @include inline-svg-icon('icon-location', #ffffff, center, 26px 26px);
    }
    &--file {
        width: 18px;
        height: 23px;
        @include inline-svg-icon('icon-pdf', #ffffff, center, 18px 23px);
    }
    &--phone {
        width: 21px;
        height: 25px;
        @include inline-svg-icon('icon-phone', #ffffff, center, 21px 25px);
    }
    &--search {
        margin-right: 0;
        width: 22px;
        height: 22px;
        @include inline-svg-icon('icon-search', #ffffff, center, 22px 22px);
    }
    &--envelope {
        width: 22px;
        height: 22px;
        @include inline-svg-icon('icon-envelope', #383e42, center, 22px 22px);
    }
    &--linkedin {
        width: 22px;
        height: 22px;
        @include inline-svg-icon('icon-linkedin', #383e42, center, 22px 22px);
    }
    &--facebook {
        width: 22px;
        height: 22px;
        @include inline-svg-icon('icon-facebook', #383e42, center, 22px 22px);
    }
    &--twitter {
        width: 22px;
        height: 22px;
        @include inline-svg-icon('icon-twitter', #383e42, center, 22px 22px);
    }
    &--print {
        width: 22px;
        height: 22px;
        @include inline-svg-icon('icon-print', #383e42, center, 22px 22px);
    }
    &--close {
        width: 25px;
        height: 25px;
        @include inline-svg-icon('icon-close', #292c37, center, 25px 25px);
    }
    &--payu {
        width: 50px;
        height: 31px;
        @include inline-svg-icon('icon-payu', #292c37, center, 50px 31px);
    }
    &--invoice {
        width: 34px;
        height: 45px;
        @include inline-svg-icon('icon-invoice', #292c37, center, 34px 45px);
    }
}

.link {
    &-stretched::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: auto;
        content: '';
        background-color: rgba(0, 0, 0, 0);
    }
}

.headersite {
    @include clearfix;
    @include respond-below(lg) {
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 1001;
        &-bottom {
            .navbar {
                display: none;
            }
            .container {
                padding: 10px;
                max-width: 100%;
                .row {
                    margin: 0;
                }
            }
            .logo {
                text-align: center;
                img {
                    width: auto;
                    height: 42px;
                }
            }
        }
    }
    &-top {
        background: #202020;
        @include respond-below(lg) {
            display: none;
        }
    }
    &-bottom {
        background: #ffffff;
    }
    .logo {
        margin: auto 0 auto 0;
        img {
            width: 220px;
        }
        a {
            display: inline-block;
        }
    }
    .nav-help {
        margin-left: auto;
    }
}

.promotional {
    background: #292c37;
    display: block;
    &-slider {
        padding-left: 0;
        @include respond-below(lg) {
            padding-right: 0;
        }
        &-inner {
            height: 100%;
            .slick-list,
            .slick-track {
                height: 100%;
            }
            li {
                margin: 0;
            }
            .item {
                position: relative;
                a {
                    height: 100%;
                    display: block;
                }
                &-data {
                    height: 100%;
                    float: right;
                    z-index: 110;
                    @include respond-between(lg, xl) {
                        width: 560px;
                    }
                    @include respond-above(xl) {
                        width: 710px;
                    }
                }
                &-image {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 100;
                    @include display-flex;
                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 1;
                        background: rgba(#000000, 0.2);
                    }
                }
                &-title {
                    margin: 4rem 0 2rem 0;
                    color: #ffffff;
                    font: normal 2.5rem/120% $font-title-extra;
                    text-shadow: 0 0 20px #000000;
                }
                &-time {
                    margin: 0 0 8px 0;
                    padding: 5px 10px;
                    color: #ffffff;
                    background: $main-color;
                    display: inline-block;
                }
                &-text {
                    padding: 5px 10px;
                    width: 70%;
                    color: #ffffff;
                    background: #000000;
                    display: block;
                }
                .btn {
                    position: absolute;
                    right: 30px;
                    bottom: 30px;
                }
            }
        }
        &-dots {
            position: absolute;
            bottom: 0;
            right: 30px;
            .slick-dots {
                margin: 0 0 30px 0;
                padding: 0;
                list-style: none;
                text-align: left;
                li {
                    margin: 0 8px 5px 0;
                    padding: 0;
                    display: inline-block;
                    button {
                        padding: 0;
                        width: 18px;
                        height: 18px;
                        border: 0;
                        display: block;
                        outline: none;
                        font-size: 0;
                        line-height: 0;
                        background: transparent;
                        border-radius: 50%;
                        border: 3px solid #ffffff;
                        @include transition(all 0.2s);
                    }
                    &.slick-active {
                        button {
                            width: 24px;
                            height: 24px;
                            background: #ffffff;
                            border: 3px solid #ffffff;
                        }
                    }
                }
            }
        }
    }
    &-text {
        &-hld {
        }
        padding-top: 20px;
        padding-bottom: 30px;
        @include clearfix;
        @include respond-between(lg, xl) {
            width: 365px;
        }
        @include respond-above(xl) {
            width: 395px;
        }
        p {
            color: #ffffff;
        }
    }
}

.insetbox {
    padding: 26px 0 40px 0;
    background: #059a99;
    display: block;
    p {
        color: #cdebeb;
    }
    .btn-primary--white {
        &:hover,
        &:focus {
            border: 1px solid #ffffff;
        }
    }
    &--admission {
        margin-bottom: 60px;
        padding: 40px 0;
        background: #292c37;
        p {
            color: #ffffff;
        }
    }
}

.theytrusted {
    background: #ffffff;
    @include clearfix;
    &-inner {
        margin-bottom: 30px;
    }
    .slick-slider {
        .slick-track {
            display: flex;
        }
        .item {
            height: auto;
            justify-content: center;
            align-self: center;
            text-align: center;
            a {
                color: #000;
                font: normal 24px/100% $font-title;
                text-decoration: none;
                &:hover,
                &:focus {
                    color: lighten(#000000, 40%);
                    img {
                        opacity: 0.4;
                    }
                }
            }
        }
    }
}

.itembox {
    margin: 0 0 30px 0;
    width: 100%;
    @include display-flex;
    @include respond-below(lg) {
        @include display-flex;
        @include flex-direction(column);
        height: 100%;
    }
    &-image-hld {
        padding: 0;
        border: 1px solid #dddddd;
        border-right: none;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        @include display-flex;
        .label {
            padding: 5px 10px;
            font-size: 0.75rem;
            position: absolute;
            top: 20px;
            right: 0;
            z-index: 100;
            text-align: right;
        }
        a,
        div {
            width: 100%;
            height: 100%;
            cursor: pointer;
            background: #fff;
            @include display-flex;
            img {
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                @include transition(all 0.2s);
                &:hover,
                &:focus {
                    @include scale3d(1.05, 1.05, 1);
                }
            }
        }
    }
    &--publication {
        .itembox-image-hld {
            .label {
                top: unset !important;
                bottom: 20px !important;
            }
            @include respond-below(lg) {
                height: 400px;
            }
            a,
            div {
                img {
                    object-fit: contain;
                }
            }
        }
    }
    &-data {
        padding: 20px;
        border: 1px solid #dddddd;
        border-left: none;
        background: #ffffff;
        @include display-flex;
        @include flex-direction(column);
    }
    &-body {
        margin-top: auto;
        align-items: stretch;
        position: relative;
        @include display-flex;
        @include flex-direction(row);
        @include respond-below(md) {
            @include display-flex;
            @include flex-direction(column);
            height: 100%;
        }
    }
    &-header {
        &-title {
            color: #000000;
            font: normal 2rem/100% $font-title;
            min-height: 40px;
            a,
            span {
                color: #000000;
                text-decoration: none;
                cursor: pointer;
                &:hover,
                &:focus {
                    color: $content-color-link-hover;
                    text-decoration: none;
                }
            }
        }
        &-subtitle {
            color: #035959;
            font: italic 1.375rem/120% $font-title-sec;
        }
    }
    &-content {
        padding: 0 20px 0 0;
        p {
            &:last-child {
                margin: 0;
            }
        }
        ul {
            margin: 5px 0;
            padding: 0;
            list-style: none;
            li {
                margin: 5px 0;
                padding: 0 0 0 24px;
                position: relative;
                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: 3px;
                    top: 8px;
                    border-radius: 50%;
                    background: $main-color;
                }
            }
        }
    }
    &-action {
        padding: 0;
        width: 100%;
        position: relative;
        @include display-flex;
        @include flex-direction(column);
        @include respond-below(md) {
            margin-top: 1rem;
        }
        .icon--calendar {
            margin-left: 5px;
            float: left;
        }
        p {
            text-align: left;
            small {
                font-size: 0.75rem;
            }
        }
        b {
            text-align: left;
            margin-bottom: 10px;
            color: #656565;
            font-size: 0.875rem;
            display: block;
        }
        .btn {
            margin-top: auto;
            width: 100%;
            text-align: center;
        }
        &-item {
            display: block;
            color: #000000;
            text-decoration: none;
            &:hover,
            &:focus {
                color: lighten(#000000, 70%);
                text-decoration: none;
            }
        }
        &--line {
            &::after {
                content: '';
                width: 1px;
                height: auto;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 60px;
                z-index: 100;
                background: #e6e6e8;
            }
            p {
                padding-left: 15px;
                text-align: right;
            }
            @include respond-below(md) {
                &::after {
                    display: none;
                }
                p {
                    padding-left: 0;
                }
            }
        }
    }
}

.listing {
    &-action {
        margin: 10px 0 60px 0;
        text-align: right;
        &--homepage {
            margin: 0 0 30px 0;
            text-align: center;
            .btn-border {
                display: block;
            }
        }
        .icon {
            margin-left: 5px;
            margin-right: 0;
            vertical-align: initial;
        }
        .pagination {
            display: inline-block;
            li {
                margin-bottom: 3px;
                display: inline-block;
                list-style: none;
                a {
                    margin: 0 0 0 4px;
                    padding: 11px 15px;
                    color: #282828;
                    font: normal 1rem/100% $font-title-sec;
                    border: 1px solid transparent;
                    text-decoration: none;
                    background: #ffffff;
                    border: 1px solid #dddddd;
                    text-decoration: none;
                    display: block;
                    &:hover,
                    &:focus {
                        color: lighten(#292c37, 10%);
                        background: darken(#ffffff, 10%);
                        border-color: lighten(#292c37, 50%);
                    }
                }
                &.active {
                    a {
                        background: $main-color;
                        border-color: $main-color;
                        color: #ffffff;
                        &:hover,
                        &:focus {
                            background: darken($main-color, 10%);
                        }
                    }
                }
                &.blank {
                    a,
                    span {
                        background: none;
                        border: 1px solid transparent;
                    }
                }
                &.prev,
                &.next {
                    a,
                    span {
                        padding: 11px 15px;
                    }
                }
                &.next {
                    .icon {
                        margin-left: 5px;
                        margin-right: 0;
                    }
                }
                &.prev {
                    .icon {
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }
            }
        }
        .btn {
            padding: 11px 15px;
        }
        .dropdown-toggle {
            .icon {
                margin: 0 0 2px 5px;
            }
        }
        .dropdown-menu {
            margin: 0;
            border: 1px solid #dddddd;
            .dropdown-item {
                a {
                    color: #282828;
                    text-decoration: none;
                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.footersite {
    padding: 25px 0 15px 0;
    background: #202020;
    @include clearfix;
    &-data {
        .logo {
            margin-bottom: 14px;
            width: 190px;
            a {
                display: block;
            }
        }
        .list {
            margin: 0 0 20px 0;
            &--icon {
                .list-item {
                    padding: 6px 0;
                    @include respond-below(md) {
                        width: 50%;
                        float: left;
                    }
                    @include respond-below(sm) {
                        width: 100%;
                    }
                }
            }
            .icon {
                width: 30px;
            }
        }
        p {
            font-size: 0.875rem;
            color: #ffffff;
        }
        > div {
            margin-bottom: 1.5rem;
            @include respond-below(md) {
                margin-bottom: 1rem;
            }
        }
    }
    &-copyright {
        padding: 10px 0;
        border-top: 1px solid #797979;
        p {
            margin: 5px 0 0 0;
            padding: 0;
            font-size: 0.75rem;
            line-height: 120%;
            color: #909090;
        }
        a {
            color: lighten(#909090, 10%);
            text-decoration: underline;
            &:hover,
            &:focus {
                color: darken(#909090, 10%);
                text-decoration: none;
            }
        }
    }
}

.popup {
    &-inline {
        margin: 15px 0;
        padding: 20px;
        border: 2px solid $main-color;
        text-align: center;
        position: relative;
        z-index: 1001;
        background: #ffffff;
        p {
            text-align: left;
        }
        .close-hld {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            .icon {
                margin: 0;
            }
        }
        .btn {
            margin-bottom: 5px;
        }
        &--listing {
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border: none;
        }
        &--details {
        }
    }
}

.js-overflow {
    position: relative;
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 110;
        pointer-events: auto;
        content: '';
        background: rgba(255, 255, 255, 0.6);
    }
}

.js-popup {
    &-hide {
        display: none;
    }
    &-show {
    }
}

.alert-cookie {
    opacity: 0;
    position: fixed;
    z-index: 1000;
    color: #191919;
    transform: translateY(100%);
    transition: all 500ms ease-out;
    width: 300px;
    padding: 15px;
    bottom: 0;
    right: 20px;
    background: #fff;
    &::before {
        margin: 0;
        content: '';
        display: table;
        clear: both;
        width: 67px;
        height: 47px;
        background: url(../../../assets/images/sprite.png) $sprite-cookie-offset-x $sprite-cookie-offset-y no-repeat;
        position: absolute;
        bottom: 0;
        left: -23px;
        z-index: 100;
    }
    p {
        font-size: 0.75rem;
        line-height: 180%;
    }
    &.show {
        opacity: 1;
        transform: translateY(0%);
        transition-delay: 1000ms;
    }
    .js-acceptcookies {
        margin-left: 10px;
        vertical-align: baseline;
        float: right;
    }
}

.fb-box {
    width: 300px;
    height: 130px;
    position: absolute;
    right: -300px;
    top: 0;
    background: #fff;
    @include respond-below(lg) {
        display: none;
    }
    &-hld {
        width: 100%;
        z-index: 991;
        position: fixed;
    }
    &-btn {
        width: 100%;
        position: relative;
        &-in {
            background: url(../../../assets/images/sprite.png) $sprite-facebook-offset-x $sprite-facebook-offset-y no-repeat;
            position: absolute;
            width: 37px;
            height: 66px;
            left: -37px;
            top: 0px;
        }
    }
}

.in-box {
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    top: 62px;
    z-index: 1010;
    background: #fff;
    @include respond-below(lg) {
        display: none;
    }
    &-hld {
        width: 100%;
        z-index: 990;
        position: fixed;
    }
    &-btn {
        width: 100%;
        position: relative;
        &-in {
            background: url(../../../assets/images/sprite.png) $sprite-linkedin-offset-x $sprite-linkedin-offset-y no-repeat;
            position: absolute;
            width: 37px;
            height: 68px;
            left: -37px;
            top: 0px;
            text-indent: -999px;
            overflow: hidden;
            &:hover,
            &:focus {
                width: 39px;
                left: -39px;
                background-position: 0 $sprite-linkedin-offset-y;
            }
        }
    }
}

.search-cover {
    padding: 2.3rem 0 3.2rem;
    width: 100%;
    background: #fff;
    position: fixed;
    z-index: 1020;
    top: 0;
    left: 0;
    transform: translate(0px, -100%);
    visibility: hidden;
    @include transition(all 0.2s);
    &-header {
        padding: 0 1rem;
        width: 100%;
        min-height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        @include align-items(center);
        @include display-flex;
        @include justify-content(flex-end);
        .js-search-close {
            background: none;
            &:hover {
                @include animation(rotate_180 300ms ease-out);
            }
            .icon--close {
                margin-right: 0;
                width: 20px;
                height: 20px;
                @include inline-svg-icon('icon-close', #292c37, center, 18px 18px);
            }
        }
    }
    .search-form {
        position: relative;
        input[type='text'] {
            margin: 0;
            padding: 0.6rem 0;
            width: 100%;
            font-size: 1.1rem;
            line-height: 1.5;
            text-transform: none;
            display: block;
            color: #000;
            outline: 0;
            border: 0;
            border-bottom: 2px solid #e6e6e6;
            @include transition(all 0.2s);
        }
        button {
            margin: 0;
            padding: 0;
            font-size: 1.25rem;
            border: 0;
            background: transparent;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            .icon--search {
                width: 32px;
                height: 32px;
                @include inline-svg-icon('icon-search', #292c37, center, 18px 18px);
            }
        }
    }
}

.autocomplete {
    &-group {
        padding: 2px 5px;

        strong {
            display: block;
            border-bottom: 1px solid #000;
        }
    }
    &-suggestions {
        background: #fff;
        overflow: auto;

        strong {
            font-weight: normal;
            color: #b11623;
        }
    }
    &-suggestion {
        padding: 0.3rem 0.5rem;
        white-space: nowrap;
        overflow: hidden;
    }
    &-selected {
        background: #f0f0f0;
        cursor: pointer;
    }
}

.widget {
    &-adsites {
        margin: 30px 0 0 0;
        width: 100%;
        text-align: center;
        img {
            text-align: center;
        }
    }
}
