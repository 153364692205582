@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans-italic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: url('../fonts/opensans-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSansCondensed';
    src: url('../fonts/opensans-condbold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-condbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansExtraBold';
    src: url('../fonts/opensans-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansExtraBold';
    src: url('../fonts/opensans-extrabolditalic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-extraolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}