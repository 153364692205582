@-webkit-keyframes rotate_180 {
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
}

@keyframes rotate_180 {
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
}